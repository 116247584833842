<template>
  <div class="register left-float-top ml-30">
   <div class="flex-1">
<!--     <div style=" width: 100%; height: 50px; background: #78a2f8; margin-bottom: 15px; line-height: 50px; color: #fff; padding-left: 20px;">-->
<!--       申请本院救治单元的医院-->
<!--     </div>-->
<!--     <el-row>-->
<!--       <el-form :inline="true" :model="search">-->
<!--         <el-form-item label="关键字">-->
<!--           <el-input v-model="search.keyword" placeholder="请输入医院名称" clearable/>-->
<!--         </el-form-item>-->
<!--         <el-form-item>-->
<!--           <el-button class="c-m-l-20" @click="searchAction">查 询</el-button>-->
<!--         </el-form-item>-->
<!--       </el-form>-->
<!--     </el-row>-->
<!--     <div style="margin-top: 20px;">-->
<!--       <el-table class="mb-20" :data="grid.items" row-key="id" border empty-text="暂无信息." @selection-change="changeTableSelection">-->
<!--         <el-table-column prop="name" label="机构名称" width="250"/>-->
<!--         <el-table-column prop="ctime" label="注册日期" width="200"/>-->
<!--         <el-table-column prop="" label="联络人姓名" width="150"/>-->
<!--         <el-table-column prop="" label="联络人电话" width="150"/>-->
<!--         <el-table-column prop="" label="阶段" width="200"/>-->
<!--       </el-table>-->
<!--       <el-pagination class="c-pagination" @size-change="changePaginationSize" @current-change="changePaginationCurrent" :current-page="grid.pagination.current"-->
<!--                      :page-sizes="grid.pagination.pageSizeOptions" :page-size="grid.pagination.pageSize" layout="total, sizes, prev, pager, next, jumper"-->
<!--                      :total="grid.pagination.total" :hide-on-single-page="true">-->
<!--       </el-pagination>-->
<!--     </div>-->

     <div style="color: black;font-size: 24px;text-align: center;margin-top: 20px;">救治单元绑定流程说明</div>
     <div class="mt-20 scroll" style="font-size: 18px; height: 768px;">
       <div class="mt-20">第一步：登录<span style="color:#4ba1d6">湖南省胸痛中心官网https://www.hunancpc.net/</span>,点击“<span style="color: red">申请注册</span>”</div>
       <img class="mt-20" src="../../assets/img/process/process1.png" width="100%" />
       <div class="mt-20">第二步：注册时选择救治单元注册，注意选择<span style="color: red">上级转诊医院</span></div>
       <img class="mt-20" src="../../assets/img/process/process2.png" width="100%" />
       <img  src="../../assets/img/process/process3.png" width="100%" />
       <div class="mt-15" style="color: red;font-size: 14px;">存在多个上级医院时，第一个医院作为救治单元的审核和认证机构</div>
       <div class="mt-20" >第三步：上级医院登录<span style="color:#4ba1d6">湖南胸痛中心认证管理平https://www.hunancpc.net/cpis-auth-admin/</span>，对申请的救治单元进行资质审核，审核通过的救治单元可以登录填报平台进行数据填报（见第五步）</div>
       <img class="mt-20" src="../../assets/img/process/process4.png" width="100%" />
       <div class="mt-20"> 第四步：救治单元从<span style="color:#4ba1d6">湖南省胸痛中心官网</span>登录（账号为注册时的账号密码），点击机构认证---->机构信息，查看审核状态</div>
       <img class="mt-20" src="../../assets/img/process/process5.png" width="100%" />
       <img class="mt-20" src="../../assets/img/process/process6.png" width="100%" />
       <div class="mt-15" style="color: red;font-size: 14px;">审核不通过时，救治单元针对不通过的原因，补充资料后再次提交审核</div>
       <div class="mt-15">第五步：机构信息显示<span  style="color: red;">通过</span> 则可以登录<span style="color:#4ba1d6">湖南省胸痛中心质量控制中心填报平台https://www.hunancpc.net/cpis-admin/</span>账号密码就是注册时的账号密码</div>
       <img class="mt-20" src="../../assets/img/process/process7.png" width="100%" />
     </div>

   </div>

  </div>
</template>
<script>
export default {
  name: "TreatmentUnit",
  data(){
    return{
      search:{},
      organId:'',
      data:[],
      grid: {
        items: [],
        selections: [],
        pagination: this.$pagination
      },
    }
  },
  mounted() {

    if (JSON.parse(localStorage.getItem('cpis-web_userInfo'))) {
      this.organId = JSON.parse(localStorage.getItem('cpis-web_userInfo')).id
    }
    this.searchAction();
  },
  methods:{
    searchAction:function (){

      this.loadGrid()

    },
    loadGrid:function (){
      const self=this;
      let params={'entity.keyword': self.search.keyword,'entity.ownedOrganId':self.organId,'entity.status':'ENABLE',start: (this.grid.pagination.current - 1) * this.grid.pagination.pageSize, length: this.grid.pagination.pageSize}
      this.$axios.get('/api/customer/web/hospital/findbypage', {params:params})
          .then((res) => {
            if(res.msg.flag == 0){
              if (null === res.biz || res.biz.empty) {
                Object.assign(self.grid, {items: [], pagination: Object.assign(self.grid.pagination, {current:1, total:0})});
              } else {
                Object.assign(self.grid, {items: res.biz.data, pagination: Object.assign(self.grid.pagination, {total:res.biz.count})});
              }
            }else{
              self.$message.error({content: res.msg.message, duration: 2});
            }
          }).catch(() => {
      });
    },
    changePaginationSize:function (value){
      this.grid.pagination.pageSize = value;
      this.searchAction();
    },
    changePaginationCurrent:function (value) {
      this.grid.pagination.current = value;
      this.loadGrid();
    },
    changeTableSelection:function (selections) {
      this.grid.selections = (selections||[]).map(e => e.id);
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}
.scroll{
  overflow-y: scroll;
}
</style>